<template>
    <div id="ap">
        <template v-if="mode == 'design'">
            <flow-design :approveId="approveId" @back="() => { mode = 'list'; }"></flow-design>
        </template>
        <template v-if="mode == 'list'">
            <a-layout>
                <div class="content-header">
                    <a-affix :offset-top="0" style="width: 100%;">
                        <a-row type="flex" justify="space-between">
                            <a-col span="12">
                                <common-page-btn>
                                    <a-button type="primary" @click="$refs.before.show()">新建工作</a-button>
                                    <a-button type="primary" :ghost="param.type === 0 ? false : true"
                                              @click="() => { param.type = 0; doRefresh(); }">我的申请</a-button>
                                    <a-button type="primary" :ghost="param.type === 1 ? false : true"
                                              @click="() => { param.type = 1; doRefresh(); }">待我审批</a-button>
                                    <a-button type="primary" :ghost="param.type === 2 ? false : true"
                                              @click="() => { param.type = 2; doRefresh(); }">审批历史</a-button>
                                </common-page-btn>
                            </a-col>
                            <a-col span="12">
                                <a-row type="flex" align="middle" justify="end" :gutter="3">
                                    <a-col span="12">
                                        <a-input v-model="param.keyword" allowClear search @pressEnter="doRefresh"
                                                 placeholder="关键词查询"
                                                 @change="() => { if (param.keyword == '') { doRefresh(); } }"></a-input>
                                    </a-col>
                                    <a-col span="6">
                                        <a-select style="width:100%" placeholder="分类筛选" v-model="param.categoryId"
                                                  allowClear @change="doRefresh">
                                            <a-select-option :value="item.categoryId" v-for=" item  in  category "
                                                             :key="item.categoryId">{{
                                                                 item.name }}</a-select-option>
                                        </a-select>
                                    </a-col>
                                    <a-col span="6">
                                        <a-select mode="default" style="width:100%" placeholder="状态筛选"
                                                  v-model="param.status" allowClear @change="doRefresh()">
                                            <a-select-option :value="-1">退回</a-select-option>
                                            <a-select-option :value="0">处理中</a-select-option>
                                            <a-select-option :value="10">审批结束</a-select-option>
                                        </a-select>
                                    </a-col>
                                </a-row>
                            </a-col>
                        </a-row>
                    </a-affix>
                </div>
            </a-layout>
            <a-layout>
                <a-layout-content>
                    <a-table :pagination="false" :style="{ background: '#FFF' }" size="small" row-key="applyId" border
                             :columns="keys" ref="list" :loading="loading" :data-source="data.records">
                        <!--标题-->
                        <template slot-scope="row" slot="apply-title">
                            <a @click="() => { isHander = false; getDetail(row); }">{{ row.title }}</a>
                        </template>
                        <!--当前审批节点-->
                        <template slot-scope="row" slot="current-flow">
                            <span v-if="row.status == '处理中'">{{ row.flowName }}</span>
                            <span v-else-if="row.status == '退回'">发起人修改</span>
                            <span v-else>流程结束</span>
                        </template>
                        <!--状态-->
                        <template slot-scope="row" slot="status">
                            <a-tooltip title="流程已结束" v-if="row.status == '已结束'">
                                <a-icon style="color: #090" type="check-circle" />
                            </a-tooltip>
                            <a-tooltip title="正在办理中" v-else-if="row.status == '处理中'">
                                <a-icon type="clock-circle" style="color: rgb(0, 119, 255);" />
                            </a-tooltip>
                            <a-tooltip title="已退回,请申请人重新提交" v-else>
                                <a-icon type="info-circle" style="color: rgb(255, 145, 0);" />
                            </a-tooltip>
                        </template>
                        <!--操作-->
                        <template slot-scope="row" slot="action">
                            <a-button-group shape="circle" size="small">
                                <a-tooltip title="重新编辑" v-if="row.status == '退回' && row.isAuth">
                                    <a-button type="link" icon="issues-close"
                                              @click="() => { isHander = true; getDetail(row); }"></a-button>
                                </a-tooltip>
                                <a-tooltip title="办理" v-if="row.status == '处理中' && row.isAuth">
                                    <a-button type="link" icon="carry-out"
                                              @click="() => { isHander = true; getDetail(row); }"></a-button>
                                </a-tooltip>
                                <a-tooltip title="删除" v-if="row.userId == apiUtil.getUser('staffId')">
                                    <a-button type="link" icon="delete" @click="del(row.applyId)"></a-button>
                                </a-tooltip>
                            </a-button-group>
                        </template>
                    </a-table>
                </a-layout-content>
            </a-layout>
            <a-row>
                <a-col span="12" offset="6" style="text-align: center; padding: 20px 0;">
                    <a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext"
                                  @showSizeChange="setLimit" :current="data.current" />
                </a-col>
            </a-row>
            <a-back-top />
        </template>

        <create-before ref="before" :category="category" @success="doRefresh"></create-before>
        <apply-detail ref="applyDetail" @success="doRefresh" :footer="isHander"></apply-detail>
        <create-form-model ref="createFormModel" @success="doRefresh"></create-form-model>
    </div>
</template>

<script>
import utils from '@/common/utils';
import apiUtil from '@/common/apiUtil';
import request from '@/config/request';
import createBefore from './components/create-before.vue';
import applyDetail from './components/apply-detail.vue';
import createFormModel from './components/create-form-model.vue';
export default {
    components: { createBefore, applyDetail, createFormModel },
    data() {
        return {
            loading: false,
            mode: 'list',
            data: [],
            category: [],
            isHander: false,
            param: {
                page: 1,
                limit: 20,
                categoryId: '',
                keyword: '',
                type: 0,
                status: '',
                userType: null
            },
            keys: [
                { title: 'ID', dataIndex: 'applyId', width: 80, align: 'center' },
                { title: '审批事项', scopedSlots: { customRender: 'apply-title' }, align: 'left', width: 300, ellipsis: true },
                { title: '发起人', dataIndex: 'userName', width: 120, align: 'center', ellipsis: true },
                { title: '发起时间', dataIndex: 'createTime', width: 160, align: 'center', ellipsis: true },
                { title: '当前审批节点', scopedSlots: { customRender: 'current-flow' }, width: 160, align: 'center', ellipsis: true },
                { title: '状态', scopedSlots: { customRender: 'status' }, width: 80, align: 'center', ellipsis: true },
                { title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 100 }
            ],
        };
    },
    created() {
        this.doRefresh();
    },
    mounted() {
        this.getCategory();
    },
    methods: {
        ...apiUtil,
        getDetail(apply) {
            utils.showSpin();
            request.get("/platform/apply/detail/" + apply.applyId, {}).then(ret => {
                // console.log(ret.data);
                if (apply.status == '退回') {
                    this.$refs.createFormModel.show(ret.data);
                } else {
                    this.$refs.applyDetail.show(ret.data);
                }
            });
        },
        getCategory() {
            request.get("/platform/approve/category", { organId: apiUtil.getDefaultOrganId() }).then(ret => {
                this.category = ret.data;
            });
        },
        // 删除
        del(applyId) {
            utils.confirm("您确认要删除吗?").then(() => {
                utils.showSpin();
                request.delete("/platform/apply/del/" + applyId, {}).then(r => {
                    utils.success('删除成功').then(() => {
                        this.doRefresh();
                    });
                });
            });
        },
        //获取数据 
        doRefresh() {
            this.param.page = 1;
            this.getList();
        },
        // 获取数据
        getList() {
            this.loading = true;
            this.param.categoryId = !utils.isEmpty(this.param.categoryId) ? this.param.categoryId : '';
            this.param.status = this.param.status != undefined ? this.param.status : '';
            this.http
                .get('/platform/apply/list', this.param)
                .then(ret => {
                    this.data = ret.data;
                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                });
        },
        //设置每页的显示条数
        setLimit(current, limit) {
            this.param.page = 1;
            this.param.limit = limit;
            this.getList();
        },
        //页码切换
        getNext(page) {
            this.param.page = page;
            this.getList();
        }
    }
};
</script>

<style scoped></style>
